<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-md-3 col-xl-6">
            <h4 class="mb-1 mt-0">Locations</h4>
        </div>
        <div class="col-md-9 col-xl-6 text-md-right">
            <div class="mt-4 mt-md-0">
                <button type="button" @click="editSelected(null)" class="btn btn-primary mb-3 mb-sm-0">
                    <i class="uil-plus mr-1"></i> New Location
                </button>
            </div>
        </div>
    </div>
    
    <div class="card">
        <div class="card-body">
            <LocationList></LocationList>
        </div>
    </div>
</div>
</template>

<script>
import LocationList from './list'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'


export default {
    page: {
        title: 'Locations',
        meta: [{
            name: 'My Locations',
            content: ""
        }],
    },
    components: {
        Layout,
        PageHeader,
        LocationList
    },
    methods: {
        editSelected(tr) {
             this.$router.push({
                name: 'EditLocation',
                params: {}
            });
        },
    }
}
</script>
