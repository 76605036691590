<template>
    <div>
        <div class="border bg-warning rounded p-4">
            <b-row class="mb-0">
                <b-col sm="12" lg="3">
                    <label class="mt-2 float-right text-white" for="inputEmail3">Select Company:</label>
                </b-col>
                <b-col sm="12" lg="4">
                    <b-form-select v-model="selectedCompany" name="Company" class="mb-2" v-on:change="getSelectedItem">
                        <option text="Please Select" value="">Please Select</option>
                        <option v-for="(item, index) in companyList" :key="index" :value="item" :text="item.name">{{
                            item.name }}</option>
                    </b-form-select>
                </b-col>
            </b-row>
        </div>
        <div class="table-responsive" v-if="locations.length > 0">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="width1"></th>
                        <th scope="col" class="width30">Name</th>
                        <th scope="col" class="width30">Company</th>
                        <th scope="col" class="width40">Address</th>
                        <th scope="col" class="width1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, i) in locations" :key="i" :data="tr">
                        <td>
                            <button type="button" class="btn btn-primary" @click="editSelected(tr)">Edit</button>
                        </td>
                        <td>
                            <div>
                                {{ tr.name }}
                            </div>
                        </td>
                        <td>
                            <div v-if="selectedCompany" class="text-primary">
                                {{ selectedCompany.name }}
                            </div>
                        </td>
                        <td>
                            {{ tr.addressLine1 }} {{ tr.addressLine2 }} {{ tr.city }} {{ tr.state }} {{ tr.postcode }}
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger"
                                @click="deleteSelected(tr.keyId)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="account-pages my-5" v-if="locations.length <= 0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-5 col-8">
                        <div class="text-center">
                            <div>
                                <img src="@assets/images/not-found.png" alt class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <h3 class="mt-3">No Locations Found</h3>
                        <p class="text-muted mb-5">
                            Create a location for your events advertisement.
                        </p>
                        <router-link to="/EditLocation" class="btn btn-lg btn-primary mt-4">New Location</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    companyData
} from '@state/api/company'

import {
    locationData
} from '@state/api/location';

export default {
    data() {
        return {
            selectedLocation: null,
            locations: [],
            selectedCompany: undefined,
            openAddWindow: true,
            companyList: []
        }
    },
    computed: {
        routeCompanyId() {
            return this.$route.params.id;
        }
    },
    watch: {
        '$route': {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                await this.loadLocations();
            }
        }
    },
    async created() {
        await this.loadCompanies();
        await this.loadLocations();
    },
    methods: {
        async getSelectedItem() {
            console.log("🚀 ~ getSelectedItem ~ this.selectedCompany:", this.selectedCompany)
            this.$router.push(`/Locations/${this.selectedCompany.keyId}`);
        },
        async loadCompanies() {
            this.companyList = await companyData.searchCustomers();
            if (this.routeCompanyId) {
                this.selectedCompany = this.companyList.filter(x => x.keyId === this.routeCompanyId)[0]
            }
        },
        async loadLocations() {
            this.locations = [];
            this.locations = await locationData.searchLocationByCompany(this.routeCompanyId, "");

            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
        async deleteSelected(id) {
            if (confirm("are you sure ? ")) {
                await locationData.deleteLocation(id);
                await this.loadLocations();
            }
        },
        editSelected(tr) {
            this.$router.push({
                name: 'EditLocation',
                params: {
                    id: tr.keyId
                }
            });
        },
    }
}
</script>
